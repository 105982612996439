import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Input,
  Button,
  FormItem,
  FormContainer,
} from 'components/ui';
import { Field, Form, Formik } from 'formik';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

function BulkMeetingForm(props) {
  const auth = useContext(AuthContext);
  const formRef = useRef();
  const intervalIdRef = useRef(null);
  const previousResponseRef = useRef(null);
  const inputFileRef = useRef();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userName, userLogin, userEmail } = useAuth();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [batchId, setBatchId] = useState('');
  const [progress, setProgress] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previousProgress, setpreviousProgress] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isEveryThingFine, setIsEveryThingFine] = useState(false);

  // Function to check import progress
  const checkImportProgress = async () => {
    if (!batchId) return;

    try {
      setIsSubmitting(true);
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_ASSET_URL}/api/import-progress/${batchId}`,
        'GET',
        null,
        {
          'Authorization': 'Bearer ' + auth.token,
        }
      );
      
      
      if (JSON.stringify(response) === JSON.stringify(previousResponseRef.current)) {
        clearInterval(intervalIdRef.current);
        return;
      }
      const hasError = response.some((res)=>{return res.status == 'error'});
      const imported = response.some((res)=>{return res.message == "Row inserted successfully."});
      const readyimport = response.some((res)=>{return res.message == "Row ready to import."});
      if(!imported && !hasError){
        setIsEveryThingFine(false);
      }
      if(!hasError && readyimport){
        setIsEveryThingFine(true);
        // inputFileRef.current = null;
      }
      previousResponseRef.current = response;
      
      setProgress(response);
    } catch (err) {
      console.error('Error fetching progress:', err);
    }
  };

  // Poll the server every 5 seconds to get progress updates
  useEffect(() => {
    if (!batchId) return; // Only start polling if batchId is set

    intervalIdRef.current = setInterval(checkImportProgress, 5000);

    return () => clearInterval(intervalIdRef.current); // Cleanup on component unmount
  }, [batchId, sendRequest]);


  return (
    <div style={{ padding: '20px 30px' }}>
      <Formik
        initialValues={{ file: null }}
        innerRef={formRef}
        validate={values => {
          const errors = {};
          if (!values.file) {
            errors.file = 'File is required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
          const formData = new FormData();
          setBatchId('');
          setProgress([]);
          setIsEveryThingFine(false);
          formData.append('file', values.file);
          console.log(inputFileRef.current.value)

          try {
            let appendUrl = isEveryThingFine? 'final-add-bulk-meetings':"add-bulk-meetings";
            const response = await sendRequest(
              `${process.env.REACT_APP_BACKEND_ASSET_URL}/api/${appendUrl}`, // Replace with your actual API URL
              'POST',
              formData,
              {
                'Authorization': 'Bearer ' + auth.token,
              }
            );
            setBatchId(response.batchId);
            setUploadedFile(values.file);
          } catch (err) {
            console.error(err);
            alert('Failed to import meetings');
          }
          setSubmitting(false);
          setFieldValue(inputFileRef.current.value);
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form id='meeting-form' onSubmit={handleSubmit} >
            <FormContainer>
              <FormItem
                label="Upload File"
                asterisk
                invalid={errors.file && touched.file}
                errorMessage={errors.file}
              >
                <Input
                  type="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  ref={inputFileRef}
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                    setIsEveryThingFine(false);
                  }}
                />
              </FormItem>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </FormContainer>
          </Form>
        )}
      </Formik>

      {/* Display Progress and Errors */}
      {batchId && 
      (<h6 style={{color: 'green'}}>File Uploaded and data queued successfully! Wait...</h6>)
      }
      {progress.length > 0 && (
        <div>
          <h6 style={{marginTop: '30px'}}>Import Progress</h6>
          <ul>
            {progress.map((item, index) => (
              <li key={index} style={{color: item.status=='error'?'red':'green'}}>
                Row {item.row_number}: {item.status} - {item.message}
              </li>
            ))}
          </ul>
        </div>
      )}
      {progress.length > 0 && isEveryThingFine && 
        <Button onClick={() => formRef.current.handleSubmit()}>
          Final Import
        </Button>
      }
    </div>
  );
}

export default BulkMeetingForm;
